
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { IDigandoErrorProps } from '../interfaces/error.interface';
import { IDigandoBasicProps } from '../interfaces/basic-props.interface';
import { IStrapiSeo, StrapiSeo } from '../seo/strapi-seo';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { defaultTranslationNamespaces } from '../constants/translations';
import { i18nextConfig } from '../next-i18next.config';
import Head from 'next/head';
import { SignInFormStandalone } from '../components/sign-in/sign-in-form-standalone';
import { FC } from 'react';
import { getServerSession } from 'next-auth';
import { authOptions } from './api/auth/[...nextauth]';
import { ssrGetLogin } from '../@types/codegen/page';
import { AllZonesFragment } from '../@types/codegen/graphql';

interface ILoginProps extends IDigandoBasicProps<{ redirectUrl: string; }>, IStrapiSeo {
  content: AllZonesFragment[];
}

const LoginPage: FC<ILoginProps> = (props) => {
  return <>
    <Head>
      <meta name='robots' content='noindex, nofollow' />
    </Head>

    <StrapiSeo pageTitle={props.pageTitle} seo={props.seo} />

    <SignInFormStandalone content={props.content} redirectUrl={props.data.redirectUrl} />
  </>;
};

export const getServerSideProps = async (props: GetServerSidePropsContext):Promise<GetServerSidePropsResult<ILoginProps | IDigandoErrorProps>> => {
  const session: SessionType = await getServerSession(props.req, props.res, authOptions());
  const redirectUrl = props.query?.redirectUrl as string ?? '/';

  if (session?.accessToken) {
    return {
      redirect: {
        permanent: false,
        destination: redirectUrl,
      },
    };
  }

  const loginPageRes = await ssrGetLogin.getServerPage({
    variables: {},
  });
  const loginPage = loginPageRes.props.data.authProcess?.data?.attributes;

  return {
    props: {
      pageTitle: 'Digando GmbH - Login',
      content: loginPage?.content as AllZonesFragment[] ?? [],
      seo: {
        title: loginPage?.loginPageTitle ?? 'Digando GmbH - Login',
        description: '',
        url: '/login',
        image: null,
        canonicals: [],
        datePublished: new Date().toDateString(),
        dateModified: new Date().toDateString(),
      },
      data: {
        redirectUrl,
      },
      ...(await serverSideTranslations(props?.locale ?? 'de', [...defaultTranslationNamespaces], i18nextConfig)),
    },
  };
}

export default LoginPage;
