import { Zones } from '../../zones/zones';
import { ContentContainerMax } from '../../layout/container';
import { SignIn } from '../sidebar/layers/sign-in/sign-in';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { mediaQueries, spacingCalculator } from '@digando/react-component-library';
import { AllZonesFragment } from '../../@types/codegen/graphql';
import { useRouter } from 'next/router';

interface SignInFormStandaloneProps {
  content: AllZonesFragment[];
  redirectUrl: string;
}

/**
 * Standalone sign in form to display on pages like login, confirmation, etc.
 *
 * The dynamic zones are passed as props to this component.
 * The first zone is displayed above the sign-in form, the rest below.
 */
export const SignInFormStandalone: FC<SignInFormStandaloneProps> = (props) => {
  const router = useRouter();

  return (
    <>
      <Zones zones={[props.content[0]]} />
      <ContentContainerMax>
        <StyledSignInContainer>
          <SignIn fullWidthHorizontalLine={false} onLogin={() => {
            if (props.redirectUrl) {
              router.push(props.redirectUrl);
              return;
            }

            router.reload()
          }} />
        </StyledSignInContainer>
      </ContentContainerMax>
      <Zones zones={props.content.slice(1)} />
    </>
  )
}

const StyledSignInContainer = styled.div`
  width: 100%;
  justify-self: center;
  grid-column: 1/span 12;
  margin-top: ${spacingCalculator(8)};
  margin-bottom: ${spacingCalculator(8)};

  @media ${mediaQueries.tablet} {
    max-width: 540px;
  }
`;
